import { getAbsoluteRoutes } from './utils';

const SHOP_BASE_URL = 'https://shop.cyberkongz.com';

export const SHOP_ROUTES = {
  HOME: '/',
  CART: '/cart',
  CHECKOUT: '/checkout',
  CHECKOUT_SUMMARY: '/checkout/summary',
  PRODUCT: (slug: string) => `/product/${slug}`,
  CATEGORY: (slug: 'cyberkongz' | 'genkai') => `/category/${slug}`,

  API_SUBMIT_DRAFT_ORDER: '/api/submit-draft-order',
  API_PAYMENT_STATUS: (paymentId: string) => `/api/payment-status?paymentId=${paymentId}`,
};

export const SHOP_ROUTES_ABSOLUTE = getAbsoluteRoutes(SHOP_ROUTES, SHOP_BASE_URL);
