import { CONTRACTS_BY_NETWORK } from '@cyberkongz/config-blockchain';
import { useEffect } from 'react';

import { ChainEnhanced } from '../config';
import { useBananaApprove } from './useBanana';
import { useChainEnhanced } from './useChainEnhanced';

function getSubdomainFactoryContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).SubdomainFactory;
}

// Those DevHelpers are generating some additional RPC requests, remember during debugging
export function DevHelpers() {
  const { chain } = useChainEnhanced();

  const { write: approveSubdomainFactory } = useBananaApprove({
    spender: getSubdomainFactoryContract(chain)?.address,
    enabled: true,
    allowance: '0',
  });

  const { write: approveKongzMartRaffle } = useBananaApprove({
    spender: '0x5dFB68e2DE8E53780C27Cc505B9a63739F64f608',
    enabled: true,
    allowance: '0',
  });

  useEffect(() => {
    console.info(`Attaching dev helpers... for ${chain?.name}`);
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.approveSubdomainFactory = () => {
      approveSubdomainFactory!();
    };
  }, [approveSubdomainFactory]);

  useEffect(() => {
    // @ts-ignore
    window.approveKongzMartRaffle = () => {
      approveKongzMartRaffle!();
    };
  }, [approveKongzMartRaffle]);

  return null;
}
