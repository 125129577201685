import {
  Flex,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  SystemStyleObject,
} from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { PropsWithChildren, ReactNode } from 'react';

import { pxToRem } from '../theme';
import { LinkArrowIcon } from './icons';

export type AppLinkProps = NextLinkProps &
  Omit<ChakraLinkProps, 'href'> & {
    children: ReactNode;
    variant?: string;
    sx?: SystemStyleObject;
  };

export function AppLink({ href, children, target, ...rest }: AppLinkProps) {
  const propsHref = typeof href === 'string' ? href : href?.pathname;
  const isInternalLink = propsHref ? propsHref.startsWith('/') || propsHref.startsWith('#') : false;

  return !target && isInternalLink ? (
    <ChakraLink
      as={NextLink}
      {...{
        href: href as string,
        target,
        textDecoration: 'none',
        ...rest,
      }}
    >
      {children}
    </ChakraLink>
  ) : (
    <ChakraLink
      {...{
        href: href as string,
        target: target || '_blank',
        rel: 'noopener noreferrer',
        textDecoration: 'none',
        ...rest,
      }}
    >
      {children}
    </ChakraLink>
  );
}

type TextWithArrowProps = PropsWithChildren & {
  sxContainer?: SystemStyleObject;
  sxIcon?: SystemStyleObject;
};

export function TextWithArrow({ sxContainer, sxIcon, children }: TextWithArrowProps) {
  return (
    <Flex sx={{ alignItems: 'center', gap: 2, ...sxContainer }}>
      {children}
      <LinkArrowIcon width={pxToRem(21)} height="auto" sx={sxIcon} />
    </Flex>
  );
}

export function AppLinkWithArrow({
  sxContainer,
  sxIcon,
  children,
  ...rest
}: AppLinkProps & { sxContainer?: SystemStyleObject; sxIcon?: SystemStyleObject }) {
  return (
    <AppLink textDecoration="none" {...rest}>
      <TextWithArrow {...{ sxContainer, sxIcon }}>{children}</TextWithArrow>
    </AppLink>
  );
}
