import { useMemo } from 'react';
import { useAccount } from 'wagmi';

import { appChains } from '../config';

export function useChainEnhanced() {
  const { chain: _chain } = useAccount();

  const chainsData = useMemo(() => {
    const chain = appChains.find((appChain) => {
      const chainId = _chain
        ? _chain.id
        : process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID
        ? parseInt(process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID, 10)
        : 1;

      return appChain.id === chainId;
    });

    const switchToChain = appChains.find((appChain) => appChain.id === chain?.switchToChainId);
    const mainnetChain = appChains.find((appChain) => appChain.id === chain?.mainnetChainId);
    const polygonChain = appChains.find((appChain) => appChain.id === chain?.polygonChainId);
    const roninChain = appChains.find((appChain) => appChain.id === chain?.roninChainId);

    return {
      chain,
      switchToChain,
      mainnetChain,
      polygonChain,
      roninChain,
      appChains,
    };
  }, [_chain?.id]);

  return {
    ...chainsData,
  };
}
