import { CONTRACTS_BY_NETWORK, MAX_ALLOWANCE, ZERO_ADDRESS } from '@cyberkongz/config-blockchain';
import { erc20Abi } from 'viem';
import { useAccount, useReadContract, useSimulateContract, useWriteContract } from 'wagmi';

import { ChainEnhanced } from '../config';
import { useChainEnhanced } from './useChainEnhanced';
import { useWatchReadContract } from './useWatchContractData';

export function getBananaContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).Banana;
}

export function getBananaPolygonContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).BananaPolygon;
}

export function getBananaRoninContract(chain?: ChainEnhanced) {
  return CONTRACTS_BY_NETWORK(chain?.name).BananaRonin;
}

export function useBananaBalance() {
  const { address } = useAccount();
  const { chain, mainnetChain } = useChainEnhanced();

  const { data: bananaBalance, queryKey } = useReadContract({
    ...getBananaContract(chain),
    functionName: 'balanceOf',
    args: [address || ZERO_ADDRESS],
    chainId: mainnetChain?.id,
    query: {
      enabled: !!chain && !!address,
    },
  });

  useWatchReadContract({ queryKey });

  return {
    bananaBalance,
  };
}

export function useBananaAllowance({ spender }: { spender: `0x${string}` }) {
  const { address } = useAccount();
  const { chain, mainnetChain } = useChainEnhanced();

  const { data: bananaAllowance, queryKey } = useReadContract({
    ...getBananaContract(chain),
    functionName: 'allowance',
    args: [address || ZERO_ADDRESS, spender],
    chainId: mainnetChain?.id,
    query: {
      enabled: !!chain && !!address && !!spender,
    },
  });

  useWatchReadContract({ queryKey });

  return {
    bananaAllowance,
  };
}

export function useBananaApprove({
  spender,
  enabled,
  allowance,
}: {
  spender: `0x${string}`;
  enabled?: boolean;
  allowance?: string;
}) {
  const { chain } = useChainEnhanced();

  const { data: dataSimulate } = useSimulateContract({
    abi: erc20Abi,
    address:
      chain?.type === 'ronin'
        ? getBananaRoninContract(chain).address
        : getBananaContract(chain).address,
    functionName: 'approve',
    args: [spender, BigInt(allowance || MAX_ALLOWANCE)],
    query: {
      enabled,
    },
  });

  const { data, writeContract } = useWriteContract();

  return {
    data,
    write: dataSimulate?.request ? () => writeContract(dataSimulate.request) : undefined,
  };
}
