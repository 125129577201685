import {
  CONTRACTS_L1_MAINNET,
  CONTRACTS_L2_POLYGON,
  CONTRACTS_L2_RONIN,
} from '@cyberkongz/config-blockchain';

const CYBERKONGZ_CONTRACT = CONTRACTS_L1_MAINNET.Kongz.address;
const CYBERKONGZ_VX_CONTRACT = CONTRACTS_L1_MAINNET.KongzVX.address;
const CYBERKONGZ_VX_POLYGON_CONTRACT = CONTRACTS_L2_POLYGON.KongzVXPolygon.address;
const CYBERKONGZ_VX_RONIN_CONTRACT = CONTRACTS_L2_RONIN.KongzVXRonin.address;
const CYBERKONGZ_VX_GEAR_RONIN_CONTRACT = CONTRACTS_L2_RONIN.Armory1155Ronin.address;

const CYBERKONGZ_GENKAI_CONTRACT = CONTRACTS_L1_MAINNET.KongzGenkai.address;
const CYBERKONGZ_GENKAI_CONTRACT_RONIN = CONTRACTS_L2_RONIN.KongzGenkaiRonin.address;
const CYBERKONGZ_EMAKI_CONTRACT_RONIN = CONTRACTS_L2_RONIN.EmakiConsumable.address;

const DOCS_BASE_URL = 'https://docs.cyberkongz.com';
const MAVIS_MARKET_BASE_URL = 'https://marketplace.skymavis.com/collections';
const OPENSEA_BASE_URL = 'https://opensea.io/collection';

export const SHARED_ROUTES = {
  // SOCIALS
  DISCORD: 'https://discord.com/invite/cyberkongz',
  TWITTER: 'https://twitter.com/CyberKongz',
  INSTAGRAM: 'https://www.instagram.com/cyberkongz/',

  // MARKETPLACES
  // BLUR
  BLUR_COLLECTION_KONGZ: `https://blur.io/collection/cyberkongz`,
  BLUR_COLLECTION_KONGZ_VX: `https://blur.io/collection/cyberkongz-vx`,
  BLUR_KONG: (id: number) => `https://blur.io/asset/${CYBERKONGZ_CONTRACT.toLowerCase()}/${id}`,
  BLUR_KONG_VX: (id: number) =>
    `https://blur.io/asset/${CYBERKONGZ_VX_CONTRACT.toLowerCase()}/${id}`,
  BLUR_KONG_GENKAI: (id: number) =>
    `https://blur.io/asset/${CYBERKONGZ_GENKAI_CONTRACT.toLowerCase()}/${id}`,

  // OPENSEA
  OPENSEA_COLLECTION_GENESIS: `${OPENSEA_BASE_URL}/cyberkongz`,
  OPENSEA_COLLECTION_BABY: `${OPENSEA_BASE_URL}/cyberkongz-babies`,
  OPENSEA_COLLECTION_VX: `${OPENSEA_BASE_URL}/cyberkongz-vx`,
  OPENSEA_COLLECTION_P_AND_K: `${OPENSEA_BASE_URL}/playandkollect`,
  OPENSEA_COLLECTION_GENKAI: `${OPENSEA_BASE_URL}/genkai`,
  OPENSEA_KONG: (id: number) =>
    `https://opensea.io/assets/ethereum/${CYBERKONGZ_CONTRACT.toLowerCase()}/${id}`,
  OPENSEA_KONG_VX: (id: number, onL1: boolean) =>
    `https://opensea.io/assets/${onL1 ? 'ethereum' : 'matic'}/${(onL1
      ? CYBERKONGZ_VX_CONTRACT
      : CYBERKONGZ_VX_POLYGON_CONTRACT
    ).toLowerCase()}/${id}`,
  OPENSEA_KONG_GENKAI: (id: number) =>
    `https://opensea.io/assets/ethereum/${CYBERKONGZ_GENKAI_CONTRACT.toLowerCase()}/${id}`,

  // MavisMarket
  MAVIS_MARKET_COLLECTION_VX: `${MAVIS_MARKET_BASE_URL}/${CYBERKONGZ_VX_RONIN_CONTRACT.toLowerCase()}`,
  MAVIS_MARKET_COLLECTION_VX_GEAR: `${MAVIS_MARKET_BASE_URL}/${CYBERKONGZ_VX_GEAR_RONIN_CONTRACT.toLowerCase()}`,
  MAVIS_MARKET_COLLECTION_GENKAI: `${MAVIS_MARKET_BASE_URL}/${CYBERKONGZ_GENKAI_CONTRACT_RONIN.toLowerCase()}`,
  MAVIS_MARKET_KONG_GENKAI: (id: number) =>
    `${MAVIS_MARKET_BASE_URL}/${CYBERKONGZ_GENKAI_CONTRACT_RONIN.toLowerCase()}/${id}`,
  MAVIS_MARKET_COLLECTION_EMAKI_CONSUMABLES: `${MAVIS_MARKET_BASE_URL}/${CYBERKONGZ_EMAKI_CONTRACT_RONIN.toLowerCase()}`,

  // Magic Eden
  MAGIC_EDEN_KONG_GENKAI: (id: number) =>
    `https://magiceden.io/item-details/eth/${CYBERKONGZ_GENKAI_CONTRACT.toLowerCase()}/${id}`,

  // own apps, external links
  BRIDGE: 'https://bridge.cyberkongz.com/',
  KLAW: 'https://theklaw.cyberkongz.com/',
  VIEWER: (id: string | number) => `https://viewer.cyberkongz.com/${id}`,

  ENS: 'https://ens.cyberkongz.com',
  GUARDIAN: 'https://guardian.cyberkongz.com/',
  GENKAI: 'https://genkai.cyberkongz.com/',

  CDN: 'https://assets.cyberkongz.com',

  SHOP_CONTACT_EMAIL: 'mailto: shop@cyberkongz.com',

  // 3d party tools/apps
  KONGZ_MART: 'https://kongzmart.cyberkongz.com/',
  ONCHAIN_KONGZ: 'https://onchainkongz.eth.limo/',
  ANALYTICS: 'https://www.ckdata.xyz/',
  NANA_CALC: 'https://nanacalc.com/',
  MAX_SCRIPT: 'https://github.com/maxbrand99/maxbanned-p2k-script',
  VX_2DEMAKER_2000: 'https://kongz-vx-demake.vercel.app/',

  TWITTER_KONGBOT: 'https://twitter.com/CyberKongzBot',

  // OTHERS
  VOTE: 'https://vote.cyberkongz.com/#/',
  PARTNERSHIPS: 'https://partnerships.cyberkongz.com/',
  DOCS: DOCS_BASE_URL,
  DOCS_PATH: (path: string) => `${DOCS_BASE_URL}${path}`,
  JUNGLE_ADVENTURE_PLAYLIST:
    'https://www.youtube.com/playlist?list=PLcgI0IIryJ5oUS9BDwMxfJbekUand088F',
  PNK_EXPLAINER_VIDEO: 'https://youtu.be/IVj3s0jr5DI',
  EMAKI_TRAILER: 'https://www.youtube.com/watch?v=W8BEGNOcY-M',

  SIXTY_TWO: 'https://sixtytwo.co/',
};
